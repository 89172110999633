<template>
    <div class="c-app" :style="appStyle" v-if="uid">
        <div style="z-index: 9999;" v-if="loading === false">
            <TheSidebar />
        </div>
        <div class="c-wrapper">
            <div style="margin-bottom: 7cap;" v-if="showHeader()">
                <header class="header-delivery text-center">
                    <template v-if="!hasSidebarToggle()">
                        <div @click="$router.go(-1)">
                            <img width="13%" src="/left-arrow.png" style="position: fixed;left: 0;top: 0;margin: 1cap;">
                        </div>
                    </template>
                    <template v-else>
                        <i v-c-emit-root-event:toggle-sidebar-mobile class="fi fi-rr-menu-burger "
                            style="position: fixed;left: 0;top: 0;margin: 2.5cap;font-size: 23px;"></i>
                    </template>
                    <span class="mt-1 h4">{{ headerName }}</span>
                </header>
            </div>
            <main class="c-main">
                <div class="c-body">
                    <div style="width: 100%;" class="container-delivery">
                        <transition name="fade">
                            <div v-if="loading === true">
                                <div class="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            <div v-else-if="loading === false">
                                <div class="text-center" v-if="orderCompleted === true" style="margin-top: 150px">
                                    <!-- <img src="https://cdn-icons-png.flaticon.com/512/4594/4594686.png" class="img-fluid"
                                        width="50%" alt="" />
                                    <h6 class="mt-4 font-weight-bold">{{ $t('endOfTransection') }}</h6>
                                    <small>{{ $t('endOfTransectionDialog') }}</small> -->
                                </div>
                                <div v-else>
                                    <transition v-if="this.$route.name !== 'DeliveryMenulists'" name="fade"
                                        mode="out-in">
                                        <router-view></router-view>
                                    </transition>
                                    <router-view v-else></router-view>

                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </main>
        </div>
    </div>
    <div v-else style="background-color: #ffffff" class="min-vh-100">
        <div class="text-center p-5">
            <img style="margin-top: 200px" src="https://cdn-icons-png.flaticon.com/512/6951/6951727.png"
                class="img-fluid" width="50%" alt="" />
            <h6 class="mt-4 font-weight-bold">
                ติดต่อพนักงานของเราเพื่อขอความช่วยเหลือ
            </h6>
            <small class="text-muted">Please contact our staff for assistance</small>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebarOrder'
import '@/util/loader.css'
import '@/util/delivery.css'

export default {
    components: {
        TheSidebar,
    },
    data() {
        return {
            loading: true,
            orderCompleted: false,

        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        appStyle() {
            const routeNames = ['DeliveryUserAddresses'];
            return {
                backgroundColor: routeNames.includes(this.$route.name) ? '#eeeeee' : '#ffffff',
                overflowX: 'clip',
            };
        },
        token() {
            return localStorage.getItem('token') || null
        },
        uid() {
            let uid = this.$route.query.uid || localStorage.getItem('uid')
            if (uid) {
                localStorage.setItem('uid', uid)
            }
            return uid
        },
        shopObjectId() {
            let objectId = this.$route.query.shopObjectId || localStorage.getItem('shopObjectId')
            if (objectId) {
                localStorage.setItem('shopObjectId', objectId)
            }

            return objectId
        },
        headerName() {
            return this.getHeader(this.$route.name)
        },
    },
    beforeCreate() {
        this.$store.dispatch('getShopLineOA')
        localStorage.setItem('orderType', 'delivery')
    },
    created() {

    },
    methods: {
        getToken() {
            return localStorage.getItem('token') || null
        },
        redirect() {
            if (this.shopLineOA.length === 0) {
                this.loading = true
            } else if (this.shopLineOA.length > 0) {
                this.loading = false
            }
        },
        hasSidebarToggle() {
            if ((this.$route.name == 'DeliveryHistory' && !this.$route.query.source) || this.$route.name == 'DeliveryUser') {
                return true
            } else {
                return false
            }
        },
        showHeader() {
            if (this.$route.name == 'DeliveryUser' && this.getToken() == null) {
                return false
            }
            else if (this.loading === false && this.$route.name !== 'DeliveryMenulists' && this.$route.name !== 'DeliveryHome') {
                return true
            }
        },
        getHeader(pathName) {
            switch (pathName) {
                case 'DeliveryMyCart':
                    return 'ตะกร้าสินค้า'
                case 'DeliveryUser':
                    return 'บัญชีของฉัน'
                case 'DeliveryHistory':
                    return 'ประวัติการสั่งซื้อ'
                case 'DeliveryUserAddresses':
                    return 'ที่อยู่จัดส่ง'
                case 'DeliveryTrackingOrder':
                    return 'ติดตามคำสั่งซื้อ'
                case 'DeliveryUserDetails':
                    return 'แก้ไขข้อมูลส่วนตัว'
                default:
                    return pathName
            }
        }
    },
    watch: {
        shopLineOA() {
            this.redirect()
        },
        $route() {
            console.log(this.$route.name)
            // $(':root').css('--success', '#ffffff');
        },
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
